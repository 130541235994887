import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wxLogin"
};
const _hoisted_2 = {
  class: "main_wx"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_wxlogin = _resolveComponent("wxlogin");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.appid && _ctx.redirect_uri ? (_openBlock(), _createBlock(_component_wxlogin, {
    key: 0,
    ref: "wxlogindata",
    appid: _ctx.appid,
    scope: "snsapi_login",
    redirect_uri: _ctx.redirect_uri,
    href: _ctx.href,
    state: _ctx.state
  }, null, 8, ["appid", "redirect_uri", "href", "state"])) : _createCommentVNode("", true)])]);
}