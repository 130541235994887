import wxlogin from 'vue-wxlogin';
import { getappId } from '@/api/user';
import { reactive, toRefs, onMounted } from 'vue';
export default {
  name: 'wxLogin',
  components: {
    wxlogin
  },

  setup() {
    const state = reactive({
      appid: '',
      redirect_uri: '',
      state: '1',
      href: ''
    });
    const refState = toRefs(state);

    const GetappIdM = () => {
      getappId().then(res => {
        let {
          data,
          status
        } = res;

        if (status == 200) {
          state.appid = data.appId;
          getRootPath();
        }
      });
    };

    const getRootPath = () => {
      let curPageUrl = window.document.location.href;
      let rootPath = curPageUrl.split('//')[0] + '//' + curPageUrl.split('//')[1].split('/')[0];
      state.redirect_uri = `${rootPath}/wxlogin`;
    };

    onMounted(() => {
      GetappIdM();
    });
    return { ...refState
    };
  }

};