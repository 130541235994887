import "core-js/modules/es.array.includes.js";
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getVerify, userLoginbycode, userLoginbypass, getUserInfo } from '@/api/user';
import usebase from '@/hooks/usebase';
import usecountdown from '@/hooks/usecountdown';
import logo from '@/components/logo';
import wxLogin from './components/wxLogin';
const __default__ = {
  name: 'login',
  props: [],
  components: {
    logo,
    wxLogin
  },

  setup() {
    // console.log(props)
    // console.log(context)
    const {
      isPhone,
      isMessage
    } = usebase();
    const {
      counttetx,
      isdisabled,
      timer,
      countdown
    } = usecountdown();
    const router = useRouter();
    const state = reactive({
      logintype: [{
        name: '验证码登录',
        type: 0
      }, {
        name: '账号密码登录',
        type: 1
      }],
      loginindex: 0,
      //登录类型 0验证码 1手机号
      //验证码
      codeform: {
        mobile: '',
        captcha: ''
      },
      //手机号
      userform: {
        username: '',
        password: ''
      },
      lookchecked: true,
      userchecked: true,
      WxShow: false,
      WxShowc: false,
      loginor: true,
      userphone: '',
      sliderOr: 0
    });
    const refState = toRefs(state);

    const changeType = e => {
      state.loginindex = e;
      state.WxShow = false;
      state.WxShowc = false;
    };

    const ShowWx = () => {
      state.WxShow = true;
      setTimeout(() => {
        state.WxShowc = true;
      }, 500);
    };

    const userXyi = () => {
      router.push('/useragreement');
    };

    const userYsi = () => {
      router.push('/privacypolicy');
    };

    const goRegister = e => {
      router.push(e);
    };

    const goLogin = () => {
      state.loginor = false;
    }; //判断token是否过期


    const getStorageExpire = () => {
      let token = localStorage.getItem('token') || '';

      if (token) {
        getUserInfo().then(res => {
          let {
            status,
            data
          } = res;

          if (status == 200) {
            let pat = /(\d{3})\d*(\d{4})/;
            state.userphone = data.phone.replace(pat, '$1****$2');
          } else {
            localStorage.removeItem('token');
            state.loginor = false;
          }
        });
      } else {
        localStorage.removeItem('token');
        state.loginor = false;
      }
    }; // 获取验证码


    const getCode = () => {
      let {
        mobile
      } = state.codeform;

      if (!mobile) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!isPhone(mobile)) {
        isMessage('warning', '请输入合法手机号');
        return;
      }

      let data = {
        phone: mobile,
        type: 'login'
      };
      getVerify(data).then(res => {
        let {
          status,
          msg
        } = res;

        if (status == 200) {
          isMessage('success', msg || '验证码发送成功');
          countdown();
          return;
        } else {
          isMessage('error', msg || '验证码发送失败');
          return;
        }
      });
    }; // 授权登录


    const autoLogin = () => {
      let token = localStorage.getItem('token');
      toPath(token);
    }; // 登录


    const handlerLogin = () => {
      // 验证码登录
      if (state.loginindex == 0) {
        let {
          mobile,
          captcha
        } = state.codeform;

        if (!mobile || !captcha) {
          isMessage('warning', '请输入登录信息');
          return;
        }

        if (!isPhone(mobile)) {
          isMessage('warning', '请输入合法手机号');
          return;
        }

        if (!state.lookchecked) {
          isMessage('warning', '请先阅读并同意《用户协议》《隐私政策》');
          return;
        }

        userLoginbycode(state.codeform).then(res => {
          let {
            status,
            msg,
            data
          } = res;

          if (status == 200) {
            loginSuccess(data);
          } else if (status == 5004) {
            isMessage('error', msg || '用户不存在');
            return;
          } else {
            isMessage('error', msg);
            return;
          }
        });
      } // 密码登录


      if (state.loginindex == 1) {
        let {
          username,
          password
        } = state.userform;

        if (!username || !password) {
          isMessage('warning', '请输入登录信息');
          return;
        }

        if (!isPhone(username)) {
          isMessage('warning', '请输入合法手机号');
          return;
        }

        if (!state.lookchecked) {
          isMessage('warning', '请先阅读并同意《用户协议》《隐私政策》');
          return;
        }

        userLoginbypass(state.userform).then(res => {
          let {
            status,
            msg,
            data
          } = res;

          if (status == 200) {
            loginSuccess(data);
          } else {
            isMessage('error', msg || '登录失败');
            return;
          }
        });
      }
    }; //  登录成功处理


    const loginSuccess = data => {
      let token = data.token;

      if (token) {
        isMessage('success', '登录成功');
        toPath(token);
      } else {
        // localStorage.removeItem('token')
        // localStorage.clear()
        state.loginor = false;
      }
    }; // 登录成功路由处理


    const toPath = token => {
      let redirect = localStorage.getItem('redirect');
      localStorage.clear();
      localStorage.setItem('token', token);

      if (!redirect) {
        window.location.href = 'http://www.ysbuy.com/';
      } else {
        if (redirect.includes('?')) {
          window.location.href = `${redirect}&code=${token}`;
        } else {
          window.location.href = `${redirect}?code=${token}`;
        }
      }
    }; // 重定向url


    const getRedirect = () => {
      let redirect = router.currentRoute.value.href.split('redirect=')[1] || localStorage.getItem('redirect') || '';
      localStorage.setItem('redirect', redirect);
    };

    onBeforeMount(() => {
      getStorageExpire();
      getRedirect();
    });
    onMounted(() => {});
    return { ...refState,
      changeType,
      getCode,
      handlerLogin,
      userXyi,
      userYsi,
      ShowWx,
      goLogin,
      autoLogin,
      goRegister,
      counttetx,
      isdisabled,
      timer
    };
  }

}; // transform: translate();

import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "c03d1576": _ctx.co
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;